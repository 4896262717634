<script setup lang="ts">
import type { PropType } from "vue";
import type { ISwiperBreakpoints } from "~/composable/swiper.type";
import type { IReview, IReviewShow } from "~/composable/review.type";
import EqZaimIcon from "~/components/icons/eqZaimIcon.vue";

const props = defineProps({
  items: {
    type: Object as PropType<IReview[]>,
    required: true,
  },
  title: {
    type: String,
    required: true,
    default: "",
  },
  description: {
    type: String,
    required: true,
    default: "",
  },
});
const breakpoints = ref<ISwiperBreakpoints>({
  0: {
    slidesPerView: 1,
    spaceBetween: 12,
  },
  640: {
    slidesPerView: 1,
    spaceBetween: 12,
  },
  960: {
    slidesPerView: 2,
    spaceBetween: 12,
  },

  1280: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
});

const reviewShow = reactive<IReviewShow>({});

for (let ind = 0; ind < props.items?.length; ind++) {
  reviewShow[`slide-${ind}`] = false;
}
</script>

<template>
  <div class="eqzaim-reviews">
    <Slider
      :show-navs="true"
      :breakpoints="breakpoints"
      class="eqzaim-slide__wrapper"
    >
      <template #title>
        <h2 class="eqzaim-reviews__title">{{ title }}</h2>
      </template>
      <template #default>
        <swiper-slide v-for="(slide, index) in items" :key="index">
          <div
            class="eqzaim-slide"
            :class="{ 'eqzaim-slide-yellow': index % 2 !== 0 }"
          >
            <div class="eqzaim-slide__content">
              <div class="flex justify-between flex-wrap gap-16px">
                <div class="flex gap-16px items-center">
                  <div v-if="slide.image">
                    <img :src="slide.image" :alt="slide.title" loading="lazy" />
                  </div>
                  <div class="flex flex-col gap-6px">
                    <div class="eqzaim-slide__fio">
                      {{ slide.fio }}
                    </div>
                    <div class="eqzaim-slide__city">
                      {{ slide.address }}
                    </div>
                  </div>
                </div>

                <div
                  class="flex justify-between items-center gap-[2px] md:w-auto w-[100px]"
                >
                  <template v-for="n in slide.rating">
                    <EqZaimIcon
                      :class="['eqzaim-slide__star', `eqzaim-slide__star-${n}`]"
                      icon-name="star2"
                      class="text-secondary-yellow-300"
                    ></EqZaimIcon>
                  </template>
                </div>
              </div>
              <div v-show="slide.title !== ''" class="eqzaim-slide__title">
                {{ slide.title }}
              </div>
              <div
                class="eqzaim-slide__text"
                :class="{
                  active: reviewShow[`slide-${index}`],
                }"
              >
                {{ slide.description }}
              </div>
              <a
                href="#"
                class="eqzaim-slide__text-readmore"
                @click.prevent="
                  reviewShow[`slide-${index}`] = !reviewShow[`slide-${index}`]
                "
                >{{ reviewShow[`slide-${index}`] ? "Скрыть" : "Читать" }}</a
              >

              <div class="flex gap-16px mt-24px">
                <div class="eqzaim-slide__date">{{ slide.date }}</div>
                <div class="eqzaim-slide__time">{{ slide.time }}</div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </template>
    </Slider>
  </div>
</template>

<style scoped lang="postcss">
.eqzaim-reviews {
  @apply container m-auto overflow-hidden;
  &__title {
    @apply xl:text-heading-L md:text-heading-XS text-heading-XXXS text-primary-black;
  }
}

.eqzaim-slide {
  @apply bg-secondary-block-blue p-content rounded-slider text-primary-black;

  &__wrapper {
    @apply lg:overflow-hidden;
  }

  &-yellow {
    @apply bg-secondary-block-yellow;
  }

  &__fio {
    @apply text-title-S;
  }

  &_city {
    @apply text-normal-S-regular;
  }

  &__title {
    @apply md:text-title-L text-title-XS mb-16px line-clamp-2 my-12px;
  }

  &__text {
    @apply overflow-hidden md:text-normal-S-regular text-normal-XS line-clamp-5 md:line-clamp-4 transition-all;
    &-readmore {
      @apply md:text-normal-S-bold text-normal-XS-bold text-primary-blue;
    }
    &.active {
      @apply line-clamp-none overflow-visible;
    }
  }

  &__date,
  &__time {
    @apply text-secondary-gray text-normal-XS;
  }

  &__image {
    @apply absolute bottom-0 lg:right-0 xl:h-auto lg:h-[160px] md:h-[145px] sm:h-[125px] h-[90px] right-1/2 translate-x-1/2 lg:translate-x-0;
  }
}
</style>
